import { stAnalytics } from "@repo/analytics";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { For, createMemo, createSignal } from "solid-js";
import { sortObjectArray } from "~/lib/array/sortObjectArray";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import styles from "./CollectionContents.module.css";
import { InlineChat } from "./InlineChat";
dayjs.extend(LocalizedFormat);

type DisplayFields = "label" | "modifiedAt";
type Sort = {
  field: DisplayFields;
  dir: SortDirection;
};

interface Props {
  collectionName: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any[];
  onMainClick: (id: string) => void;
}

export const CollectionContents = (props: Props) => {
  const [sortOrder, setSortOrder] = createSignal<Sort>({
    field: "label",
    dir: "asc",
  });

  const data = createMemo(() => sortObjectArray(sortOrder().field, props.data || [], sortOrder().dir));

  const onClick = (property: DisplayFields) => {
    return () => {
      if (sortOrder().field === property) {
        setSortOrder({
          field: property,
          dir: sortOrder().dir === "asc" ? "desc" : "asc",
        });
      } else {
        setSortOrder({
          field: property,
          dir: "asc",
        });
      }
    };
  };
  return (
    <div class={styles["collection-contents"]}>
      <table
        class={styles["collection-contents__table"]}
        on:click={(event: Event) => {
          const target = event.target as HTMLElement;
          if (target) {
            const row = target.closest("tr");
            if (row?.parentElement && row.parentElement.tagName !== "THEAD") {
              const id = row.getAttribute("data-id") as string;
              stAnalytics.track("click_tracking", {
                cta: "navigate_to_chat",
                position: "main_content_table",
                screen: getScreenType(),
              });
              props.onMainClick(id);
            }
          }
        }}
      >
        <caption class="screen-reader">{`Table representing the ${props.collectionName} collection contents.`}</caption>
        <thead class={styles["collection-contents__header"]}>
          <tr class={styles["collection-contents__header-row"]}>
            {/* <th class={styles.collectionContentsCheckboxCell}>&nbsp;</th> */}
            <th class={styles["collection-contents__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["collection-contents__header-button"],
                  sortOrder().field === "label"
                    ? sortOrder().dir === "asc"
                      ? styles["collection-contents__header-button--asc"]
                      : styles["collection-contents__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by name"
                onClick={onClick("label")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["collection-contents__header-label"]}>Name</span>
                <span class={styles["collection-contents__sort-indicator"]} />
              </button>
            </th>
            {/* <th class={styles.collectionContentsHeaderCell}>
              <button
                type="button"
                class={classNames(
                  styles.collectionContentsHeaderButton,
                  sortOrder().field === "owner"
                    ? sortOrder().dir === "asc"
                      ? styles.collectionContentsHeaderButtonAsc
                      : styles.collectionContentsHeaderButtonDesc
                    : "",
                )}
                aria-label="Sort by name"
                onClick={onClick("owner")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles.collectionContentsHeaderLabel}>Owner</span>
                <span class={styles.collectionContentsSortIndicator} />
              </button>
            </th> */}
            <th class={styles["collection-contents__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["collection-contents__header-button"],
                  sortOrder().field === "modifiedAt"
                    ? sortOrder().dir === "asc"
                      ? styles["collection-contents__header-button--asc"]
                      : styles["collection-contents__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by last modified"
                onClick={onClick("modifiedAt")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["collection-contents__header-label"]}>Last Modified</span>
                <span class={styles["collection-contents__sort-indicator"]} />
              </button>
            </th>
            {/* <th class={styles.collectionContentsHeaderCell}>
              <span class={styles.collectionContentsHeaderLabel} />
            </th> */}
          </tr>
        </thead>
        <tbody class={styles["collection-contents__body"]} aria-busy={false}>
          <For each={data()}>
            {(item) => {
              return (
                <tr class={styles["collection-contents__row"]} tabIndex={0} data-id={item.threadId}>
                  {/* <td class={styles.collectionContentsCheckboxCell}>
                    <Checkbox label="Select asset" name={`checkbox-${item.id}`} value="true" />
                  </td> */}
                  <td class={styles["collection-contents__cell"]}>
                    <InlineChat label={item.label} />
                  </td>
                  {/* <td class={styles.collectionContentsCell}>
                    <InlineUserAvatar name={item.owner} size="inline" initial={item.name.substring(0, 1)} />
                  </td> */}
                  <td class={styles["collection-contents__cell"]}>
                    <span class={styles["collection-contents__cell-text"]}>{dayjs(item.modifiedAt).format("LL")}</span>
                  </td>
                  {/* <td class={styles.collectionContentsCell} /> */}
                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
    </div>
  );
};
