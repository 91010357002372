import type { IconTypes } from "solid-icons";
import {
  BsFileEarmark,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeHtml,
  BsFiletypeJpg,
  BsFiletypeMd,
  BsFiletypeMdx,
  BsFiletypeOtf,
  BsFiletypePdf,
  BsFiletypePpt,
  BsFiletypePptx,
  BsFiletypeTxt,
  BsFiletypeXls,
  BsFiletypeXlsx,
  BsFiletypeXml,
} from "solid-icons/bs";

const FILE_TYPE_ICONS = {
  default: {
    icon: BsFileEarmark,
    theme: "file-type-grey",
  },
  doc: {
    icon: BsFiletypeDoc,
    theme: "file-type-blue",
  },
  docx: {
    icon: BsFiletypeDocx,
    theme: "file-type-blue",
  },
  html: {
    icon: BsFiletypeHtml,
    theme: "file-type-blue",
  },
  jpg: {
    icon: BsFiletypeJpg,
    theme: "file-type-teal",
  },
  md: {
    icon: BsFiletypeMd,
    theme: "file-type-burgundy",
  },
  mdx: {
    icon: BsFiletypeMdx,
    theme: "file-type-burgundy",
  },
  pdf: {
    icon: BsFiletypePdf,
    theme: "file-type-burgundy",
  },
  ppt: {
    icon: BsFiletypePpt,
    theme: "file-type-burgundy",
  },
  pptx: {
    icon: BsFiletypePptx,
    theme: "file-type-burgundy",
  },
  txt: {
    icon: BsFiletypeTxt,
    theme: "file-type-blue",
  },
  otf: {
    icon: BsFiletypeOtf,
    theme: "file-type-blue",
  },
  xls: {
    icon: BsFiletypeXls,
    theme: "file-type-green",
  },
  xlsx: {
    icon: BsFiletypeXlsx,
    theme: "file-type-green",
  },
  xml: {
    icon: BsFiletypeXml,
    theme: "file-type-red",
  },
} as const;
export type FileTypeIcon = keyof typeof FILE_TYPE_ICONS;
export type FileTypeIconTheme = { icon: IconTypes; theme: string };

/**
 * Provides the appropriate icon & color for a given file type, or falls back to the default.
 */
export const useFileTypeIcon = (type: FileTypeIcon): FileTypeIconTheme => {
  return FILE_TYPE_ICONS[type] || FILE_TYPE_ICONS.default;
};
