import { type AssetLifecycleState, failedProcessingStatus, notProcessingStatus } from "@repo/client";

export const isProcessingAssetState = (state: AssetLifecycleState[keyof AssetLifecycleState]): boolean => {
  return !Object.values(notProcessingStatus).includes(state as notProcessingStatus);
};

export const isFailedAssetState = (state: AssetLifecycleState[keyof AssetLifecycleState]): boolean => {
  return Object.values(failedProcessingStatus).includes(state as failedProcessingStatus);
};

export const readableProcessingState = (
  state: AssetLifecycleState[keyof AssetLifecycleState],
): ProcessingReadableState => {
  if (isFailedAssetState(state)) {
    return ProcessingReadableState.Failed;
  }
  if (isProcessingAssetState(state)) {
    return ProcessingReadableState.Processing;
  }
  return ProcessingReadableState.Ready;
};

export enum ProcessingReadableState {
  Ready = "Ready",
  Failed = "Failed",
  Processing = "Processing",
}
