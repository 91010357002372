import { TbMessage } from "solid-icons/tb";
import styles from "./InlineChat.module.css";

interface Props {
  label: string;
}

export const InlineChat = (props: Props) => {
  return (
    <p class={styles["inline-chat"]}>
      <span class={styles["inline-chat__icon-container"]}>
        <TbMessage class={styles["inline-chat__icon"]} size="1.25rem" />
      </span>
      <span class={styles["inline-chat__label"]}>{props.label}</span>
    </p>
  );
};
