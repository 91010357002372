import styles from "./InlineAssetSummary.module.css";

interface Props {
  summary: string;
}

export const InlineAssetSummary = (props: Props) => {
  return (
    <p class={styles["inline-asset-summary"]}>
      {/* <StIcon icon={AiOutlineFile} class={styles.inlineAssetSummaryIcon} size="1.25rem" /> */}
      <span class={styles["inline-asset-summary__text"]}>{props.summary}</span>
    </p>
  );
};
