import { type ParentProps, splitProps } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./ContentContainer.module.css";

interface Props extends ParentProps {
  class?: string;
  modifier?: "background-overlay" | "wide";
  root?: boolean;
  body?: boolean;
}

export const ContentContainer = (props: Props) => {
  const [local, rest] = splitProps(props, ["class", "modifier", "root", "body", "children"]);
  return (
    <section
      {...rest}
      class={classNames(
        styles["content-container"],
        local.modifier === "background-overlay" ? styles["content-container--background-overlay"] : "",
        local.modifier === "wide" ? styles["content-container--wide"] : "",
        local.root ? styles["content-container--root"] : "",
        local.body ? styles["content-container--body"] : "",
        local.class,
      )}
    >
      {local.children}
    </section>
  );
};
