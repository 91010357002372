import { stAnalytics } from "@repo/analytics";
import { TbArchive, TbMessage2 } from "solid-icons/tb";
import { type JSX, type ParentProps, Show, onMount } from "solid-js";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import { MAIN_CONTENT_TAB_OPTIONS, type MainContentTabOptions, useUIState } from "~/ui/UIState";
import styles from "./MainContentTabs.module.css";

interface Props extends ParentProps {
  Chats: JSX.Element;
  Assets: JSX.Element;
  // KitchenSink: JSX.Element;
  class?: string;
}

export const MainContentTabs = (props: Props) => {
  const state = useUIState();
  const [tab, setTab] = state.mainContentTab;

  // Keyboard navigation
  let focusIndex = MAIN_CONTENT_TAB_OPTIONS.indexOf(tab());
  const refs: HTMLButtonElement[] = [];
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      const previousIndex = focusIndex - 1 < 0 ? MAIN_CONTENT_TAB_OPTIONS.length - 1 : focusIndex - 1;
      if (refs[previousIndex]) {
        refs[previousIndex].focus();
        focusIndex = previousIndex;
      }
    }
    if (event.key === "ArrowRight") {
      const nextIndex = focusIndex + 1 > MAIN_CONTENT_TAB_OPTIONS.length - 1 ? 0 : focusIndex + 1;
      if (refs[nextIndex]) {
        refs[nextIndex].focus();
        focusIndex = nextIndex;
      }
    }
  };

  const resetTabIndexToActive = () => {
    refs.forEach((ref, index) => {
      ref.tabIndex = tab() === MAIN_CONTENT_TAB_OPTIONS[index] ? 0 : -1;
    });
  };

  //  Ensure focusIndex is correctly maintained when using tabs.
  const tabOnFocusIn = (value: MainContentTabOptions) => {
    const index = MAIN_CONTENT_TAB_OPTIONS.indexOf(value);
    return (event: FocusEvent) => {
      const target = event.target as HTMLButtonElement;
      if (index !== -1) {
        focusIndex = index;
        target.tabIndex = 0;
      }
    };
  };

  const tabOnFocusOut = (event: FocusEvent) => {
    if (refs.filter((element) => element.matches(":focus")).length === 0) {
      resetTabIndexToActive();
    }
  };

  const onClick = (value: MainContentTabOptions) => {
    return () => {
      setTab(value);
      stAnalytics.track("click_tracking", {
        cta: value === "Chats" ? "main_content_tab_chats" : "main_content_tab_assets",
        position: "main_content",
        screen: getScreenType(),
      });
      resetTabIndexToActive();
    };
  };

  onMount(() => {
    resetTabIndexToActive();
  });

  return (
    <div class={styles["main-content-tabs"]}>
      <div role="tablist" class={styles["main-content-tabs__container"]}>
        <div class={styles["main-content-tabs__triggers"]}>
          <button
            id="main-content-chats-tab"
            data-testid="main-content-chats-tab"
            class={classNames(
              styles["main-content-tabs__trigger"],
              tab() === "Chats" ? styles["main-content-tabs__trigger--active"] : "",
            )}
            type="button"
            role="tab"
            aria-controls="main-content-chats-content"
            onKeyUp={onKeyUp}
            onClick={onClick("Chats")}
            onFocusIn={tabOnFocusIn("Chats")}
            onFocusOut={tabOnFocusOut}
            ref={(ref) => {
              refs[0] = ref;
            }}
          >
            <span class="screen-reader">View your</span>
            <span class={styles["main-content-tabs__trigger-label"]}>Chat History</span>
            <TbMessage2 class={styles["main-content-tabs__trigger-icon"]} size="1.125rem" />
            <span aria-hidden="true" class={styles["main-content-tabs__indicator"]} />
          </button>
          <button
            id="main-content-assets-tab"
            data-testid="main-content-assets-tab"
            class={classNames(
              styles["main-content-tabs__trigger"],
              tab() === "Assets" ? styles["main-content-tabs__trigger--active"] : "",
            )}
            type="button"
            role="tab"
            aria-controls="main-content-assets-content"
            onKeyUp={onKeyUp}
            onClick={onClick("Assets")}
            onFocusIn={tabOnFocusIn("Assets")}
            onFocusOut={tabOnFocusOut}
            ref={(ref) => {
              refs[1] = ref;
            }}
          >
            <span class="screen-reader">View your recent</span>
            <span class={styles["main-content-tabs__trigger-label"]}>Stored Assets</span>
            <TbArchive class={styles["main-content-tabs__trigger-icon"]} size="1.125rem" />
            <span aria-hidden="true" class={styles["main-content-tabs__indicator"]} />
          </button>
        </div>
        {/* <button
          id="main-content-kitchen-sink-tab"
          data-testid="main-content-kitchen-sink-tab"
          class={classNames(
            styles.mainContentTabsTrigger,
            tab() === "KitchenSink" ? styles.mainContentTabsTriggerActive : "",
          )}
          type="button"
          role="tab"
          aria-controls="main-content-kitchen-sink-content"
          onKeyUp={onKeyUp}
          onClick={onClick("KitchenSink")}
          onFocusIn={tabOnFocusIn("KitchenSink")}
          onFocusOut={tabOnFocusOut}
          ref={(ref) => {
            refs[2] = ref;
          }}
        >
          <span class="screen-reader">View your recent</span>
          <span class={styles.mainContentTabsTriggerLabel}>Kitchen Sink</span>
          <TbToolsKitchen class={styles.mainContentTabsTriggerIcon} size="1.125rem" />
          <span aria-hidden="true" class={styles.mainContentTabsIndicator} />
        </button> */}
      </div>
      {/* !TODO - Review accessibility of removing elements from the DOM, this will cause validation failure */}
      <div
        id="main-content-chats-content"
        role="tabpanel"
        aria-labelledby="main-content-chats-tab"
        class={tab() === "Chats" ? styles["main-content-tabs__content--visible"] : styles["main-content-tabs__content"]}
      >
        <Show when={tab() === "Chats"}>{props.Chats}</Show>
      </div>
      <div
        id="main-content-assets-content"
        role="tabpanel"
        aria-labelledby="main-content-assets-tab"
        class={
          tab() === "Assets" ? styles["main-content-tabs__content--visible"] : styles["main-content-tabs__content"]
        }
      >
        <Show when={tab() === "Assets"}>{props.Assets}</Show>
      </div>
      {/* <div
        id="main-content-kitchen-sink-content"
        role="tabpanel"
        aria-labelledby="main-content-kitchen-sink-tab"
        class={tab() === "KitchenSink" ? styles.mainContentTabsContentVisible : styles.mainContentTabsContent}
      >
        <Show when={tab() === "KitchenSink"}>{props.KitchenSink}</Show>
      </div> */}
    </div>
  );
};
