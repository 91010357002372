import { GettingStartedChat } from "~/components/SideBar/GettingStartedChat";
import { GettingStartedUpload } from "~/components/SideBar/GettingStartedUpload";
import { ContentContainer } from "~/components/_containers/ContentContainer";
import { FlexibleGrid } from "~/components/_containers/FlexibleGrid";
import { PersistentPrompt } from "~/domains/chat/prompt/Prompt";
import styles from "./CollectionsGettingStartedScreen.module.css";
import type { Component } from "solid-js";
import type { projects } from "@repo/client";

export const CollectionsGettingStartedScreen: Component<{ assets: projects.AssetSnapshot[]; refetch: () => void }> = (
  props,
) => {
  // const { submitPrompt, setTransformationId } = usePromptContext();

  // const popular = popularUsecases
  //   // biome-ignore lint/style/noNonNullAssertion: <explanation>
  //   .map(([, , , prompt]) => campaigns.prompts.find((p) => p.id === prompt)!)
  //   .filter((l) => l);

  // const [slidingWindow, setSlidingWindow] = createSignal(0);
  // const getSamples = () => {
  //   const i = slidingWindow() * 3;
  //   return [...popular].slice(i, i + 3);
  // };
  // const nextSample = () => {
  //   const len = Math.floor((popular.length - 1) / 3);
  //   let next = slidingWindow() + 1;
  //   if (next > len) next = 0;
  //   setSlidingWindow(next);
  // };

  return (
    <main class={styles["getting-started-screen"]}>
      <ContentContainer body>
        <div class={styles["getting-started-screen__header"]}>
          <h2 class={styles["getting-started-screen__heading"]}>👋 Welcome to Storytell!</h2>
          <h3 class={styles["getting-started-screen__subheading"]}>Let's get you started.</h3>
        </div>

        <FlexibleGrid columns="two" stretch margin="large">
          {/* <GettingStartedCollections /> */}
          <GettingStartedUpload refetch={props.refetch} assets={props.assets} />
        </FlexibleGrid>
        <FlexibleGrid columns="two" stretch margin="large">
          <GettingStartedChat />
        </FlexibleGrid>

        {/* <SectionHeader title="Try" icon={RiSystemQuestionLine} />
        <StandardGrid columns="three">
          <For each={getSamples()}>
            {(thread) => (
              <LargeChatCTA
                data-test-id="upload"
                label={thread.name}
                onClick={() => {
                  setTransformationId(thread.id);
                  submitPrompt({ text: thread.name, mentionedAssets: [] });
                }}
                accessibleSuffix=""
              />
            )}
          </For>
        </StandardGrid>

        <button type="button" onClick={nextSample}>
          Refresh
        </button> */}
        <PersistentPrompt />
      </ContentContainer>
    </main>
  );
};
