import { For, type ParentProps, children } from "solid-js";
import { classNames } from "~/lib/classNames";
import { capitalize } from "~/lib/string/capitalize";
import styles from "./StandardGrid.module.css";

interface Props extends ParentProps {
  columns: "one" | "two" | "three" | "four";
}

export const StandardGrid = (props: Props) => {
  const resolved = children(() => props.children);
  return (
    <div class={classNames(styles.grid, styles[`grid--${props.columns}`])}>
      <For each={resolved.toArray()}>{(child) => <div class={styles.grid__item}>{child}</div>}</For>
    </div>
  );
};
