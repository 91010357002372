import styles from "./GettingStarted.module.css";

export const GettingStartedChat = () => {
  return (
    <div class={styles["getting-started"]}>
      <p class={styles["getting-started__step"]}>
        <span class={styles["getting-started__step-text"]}>2.</span>
      </p>
      <p class={styles["getting-started__heading"]}>
        <span class={styles["getting-started__heading-title"]}>Start chatting.</span>
        {/* <span class={styles["getting-started__heading-subtext"]}>
          <span>Find the prompt bar and start chatting with your</span>
          <span class={styles["getting-started__accordion"]}>
            <StIcon class={styles["getting-started__accordion-icon"]} icon={BsPersonFillLock} size="1.5rem" />
            <span class={styles["getting-started__accordion-text"]}>Personal Collection</span>
          </span>
        </span> */}
        <span class={styles["getting-started__heading-subtitle"]}>
          Ask anything you'd like to know about the assets uploaded.
        </span>
        {/* <StIcon icon={TbArrowBigDownFilled} class={styles["getting-started__arrow"]} size="2.625rem" /> */}
      </p>
    </div>
  );
};
