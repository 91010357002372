import { stAnalytics } from "@repo/analytics";
import { RiSystemUploadCloud2Line } from "solid-icons/ri";
import { Match, Show, Switch } from "solid-js";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useUIState } from "~/ui/UIState";
import { StIcon } from "../icons";
import styles from "./LargeFileUpload.module.css";

interface Props {
  solid?: boolean;
  hideLabel?: boolean;
  showUploadModal: () => void;
}

export const LargeFileUpload = (props: Props) => {
  const [isDesktop] = useUIState().isDesktop;

  return (
    <button
      type="button"
      class={classNames(styles["large-file-upload"], props.solid ? styles["large-file-upload--solid"] : "")}
      onClick={() => {
        props.showUploadModal();
        stAnalytics.track("click_tracking", {
          cta: "upload",
          position: "main_content",
          screen: getScreenType(),
        });
      }}
    >
      <StIcon icon={RiSystemUploadCloud2Line} class={styles["large-file-upload__icon"]} size={42} />
      <Switch fallback={<span class={styles["large-file-upload__text"]}>Upload a file</span>}>
        <Match when={isDesktop()}>
          <span class={styles["large-file-upload__message"]}>
            <Show when={!props.hideLabel}>
              <span class={styles["large-file-upload__text"]}>Upload</span>
            </Show>
            <Show when={props.hideLabel}>
              <span class="screen-reader">Upload files</span>
            </Show>
            <span class={styles["large-file-upload__highlighted-text"]}>Drag files onto the page or click here.</span>
          </span>
        </Match>
      </Switch>
    </button>
  );
};
