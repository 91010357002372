// Generated by go-ts-exporter. DO NOT EDIT.

export const notProcessingStatus = {
  NPArchived: "archived",
  NPDeleteRequested: "delete_requested",
  NPDeleted: "deleted",
  NPReady: "ready",
} as const;

export type notProcessingStatus = typeof notProcessingStatus[keyof typeof notProcessingStatus];
