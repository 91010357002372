// Generated by go-ts-exporter. DO NOT EDIT.

export const failedProcessingStatus = {
  FSFailed: "failed",
  FSExtractingFailed: "extraction_failed",
  FSTranscriptionFailed: "transcription_failed",
  FSEmbeddingFailed: "embeddings_failed",
  FSSummarizationFailed: "summarization_failed",
  FSKeywordsFailed: "keywords_failed",
} as const;

export type failedProcessingStatus = typeof failedProcessingStatus[keyof typeof failedProcessingStatus];
