import { Dynamic, Match, Show, Switch } from "solid-js/web";
import { classNames } from "~/lib/classNames";
import { type FileTypeIcon, useFileTypeIcon } from "~/ui/useFileTypeIcon";
import styles from "./InlineAsset.module.css";
import type { AssetLifecycleState } from "@repo/client";
import { readableProcessingState } from "~/lib/ui/readableProcessingState";
import { StIcon } from "../icons";
import { TbAlertCircle } from "solid-icons/tb";
import { TiTick } from "solid-icons/ti";
import { Spinner } from "../Loaders";

interface Props {
  name: string;
  mimeType: string;
  inline?: boolean;
  class?: string;
  status?: AssetLifecycleState;
}

export const InlineAsset = (props: Props) => {
  const iconTheme = useFileTypeIcon(props.mimeType as FileTypeIcon);
  return (
    <p class={classNames(styles["inline-asset"], props.inline ? styles["inline-asset--inline"] : "", props.class)}>
      <span class={styles["inline-asset__icon-container"]}>
        <Dynamic component={iconTheme.icon} class={iconTheme.theme} size="1rem" />
      </span>
      <span class={styles["inline-asset__name"]}>{props.name}</span>

      <Show when={props.status}>
        <Switch>
          <Match when={readableProcessingState(props.status as AssetLifecycleState) === "Failed"}>
            <span class={classNames(styles["inline-asset__status"], styles["inline-asset__status--failed"])}>
              <StIcon icon={TbAlertCircle} size="1.25rem" />
              <span class={styles["inline-asset__status-text"]}>
                {readableProcessingState(props.status as AssetLifecycleState)}
              </span>
            </span>
          </Match>
          <Match when={readableProcessingState(props.status as AssetLifecycleState) === "Processing"}>
            <span class={classNames(styles["inline-asset__status"], styles["inline-asset__status--processing"])}>
              <Spinner class={styles["inline-asset__status--processing"]} />
              <span class={styles["inline-asset__status-text"]}>
                {readableProcessingState(props.status as AssetLifecycleState)}
              </span>
            </span>
          </Match>
          <Match when={readableProcessingState(props.status as AssetLifecycleState) === "Ready"}>
            <span class={classNames(styles["inline-asset__status"], styles["inline-asset__status--ready"])}>
              <StIcon icon={TiTick} size="1.25rem" />
              <span class={styles["inline-asset__status-text"]}>
                {readableProcessingState(props.status as AssetLifecycleState)}
              </span>
            </span>
          </Match>
        </Switch>
      </Show>
    </p>
  );
};
